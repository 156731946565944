import { mapGetters } from 'vuex'
import { types } from '@/store'

export const settingsStateMapGetter = () =>
  mapGetters({
    settings: types.settings,
    navbarColor: types.navbarColor,
    selectedRace: types.selectedRace,
    selectedRaceStages: types.selectedRaceStages,
    selectedRaceWeeks: types.selectedRaceWeeks,
    selectedRaceRankingtypes: types.selectedRaceRankingtypes,
    activeRaces: types.activeRaces,
    historicalRaces: types.historicalRaces
  })
