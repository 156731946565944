export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"name":"English","code":"en","iso":"en-US","file":"en-US.json"},{"name":"Nederlands","code":"nl","iso":"nl-NL","file":"nl-NL.json"},{"name":"Italiano","code":"it","iso":" it-IT","file":"it-IT.json"},{"name":"Deutsch","code":"de","iso":" de-DE","file":"de-DE.json"},{"name":"Français","code":"fr","iso":" fr-FR","file":"fr-FR.json"},{"name":"Español","code":"es","iso":" es-ES","file":"es-ES.json"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"fantasy_cycling_i18n","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true,"onlyOnRoot":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  loadLanguagesAsync: true,
  normalizedLocales: [{"name":"English","code":"en","iso":"en-US","file":"en-US.json"},{"name":"Nederlands","code":"nl","iso":"nl-NL","file":"nl-NL.json"},{"name":"Italiano","code":"it","iso":" it-IT","file":"it-IT.json"},{"name":"Deutsch","code":"de","iso":" de-DE","file":"de-DE.json"},{"name":"Français","code":"fr","iso":" fr-FR","file":"fr-FR.json"},{"name":"Español","code":"es","iso":" es-ES","file":"es-ES.json"}],
  localeCodes: ["en","nl","it","de","fr","es"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "U",
  6: "S",
  7: ".",
  8: "j",
  9: "s",
  10: "o",
  11: "n",
  12: "\"",
  13: ":",
  14: "\"",
  15: ".",
  16: ".",
  17: "/",
  18: "l",
  19: "o",
  20: "c",
  21: "a",
  22: "l",
  23: "e",
  24: "s",
  25: "/",
  26: "e",
  27: "n",
  28: "-",
  29: "U",
  30: "S",
  31: ".",
  32: "j",
  33: "s",
  34: "o",
  35: "n",
  36: "\"",
  37: ",",
  38: "\"",
  39: "n",
  40: "l",
  41: "-",
  42: "N",
  43: "L",
  44: ".",
  45: "j",
  46: "s",
  47: "o",
  48: "n",
  49: "\"",
  50: ":",
  51: "\"",
  52: ".",
  53: ".",
  54: "/",
  55: "l",
  56: "o",
  57: "c",
  58: "a",
  59: "l",
  60: "e",
  61: "s",
  62: "/",
  63: "n",
  64: "l",
  65: "-",
  66: "N",
  67: "L",
  68: ".",
  69: "j",
  70: "s",
  71: "o",
  72: "n",
  73: "\"",
  74: ",",
  75: "\"",
  76: "i",
  77: "t",
  78: "-",
  79: "I",
  80: "T",
  81: ".",
  82: "j",
  83: "s",
  84: "o",
  85: "n",
  86: "\"",
  87: ":",
  88: "\"",
  89: ".",
  90: ".",
  91: "/",
  92: "l",
  93: "o",
  94: "c",
  95: "a",
  96: "l",
  97: "e",
  98: "s",
  99: "/",
  100: "i",
  101: "t",
  102: "-",
  103: "I",
  104: "T",
  105: ".",
  106: "j",
  107: "s",
  108: "o",
  109: "n",
  110: "\"",
  111: ",",
  112: "\"",
  113: "d",
  114: "e",
  115: "-",
  116: "D",
  117: "E",
  118: ".",
  119: "j",
  120: "s",
  121: "o",
  122: "n",
  123: "\"",
  124: ":",
  125: "\"",
  126: ".",
  127: ".",
  128: "/",
  129: "l",
  130: "o",
  131: "c",
  132: "a",
  133: "l",
  134: "e",
  135: "s",
  136: "/",
  137: "d",
  138: "e",
  139: "-",
  140: "D",
  141: "E",
  142: ".",
  143: "j",
  144: "s",
  145: "o",
  146: "n",
  147: "\"",
  148: ",",
  149: "\"",
  150: "f",
  151: "r",
  152: "-",
  153: "F",
  154: "R",
  155: ".",
  156: "j",
  157: "s",
  158: "o",
  159: "n",
  160: "\"",
  161: ":",
  162: "\"",
  163: ".",
  164: ".",
  165: "/",
  166: "l",
  167: "o",
  168: "c",
  169: "a",
  170: "l",
  171: "e",
  172: "s",
  173: "/",
  174: "f",
  175: "r",
  176: "-",
  177: "F",
  178: "R",
  179: ".",
  180: "j",
  181: "s",
  182: "o",
  183: "n",
  184: "\"",
  185: ",",
  186: "\"",
  187: "e",
  188: "s",
  189: "-",
  190: "E",
  191: "S",
  192: ".",
  193: "j",
  194: "s",
  195: "o",
  196: "n",
  197: "\"",
  198: ":",
  199: "\"",
  200: ".",
  201: ".",
  202: "/",
  203: "l",
  204: "o",
  205: "c",
  206: "a",
  207: "l",
  208: "e",
  209: "s",
  210: "/",
  211: "e",
  212: "s",
  213: "-",
  214: "E",
  215: "S",
  216: ".",
  217: "j",
  218: "s",
  219: "o",
  220: "n",
  221: "\"",
  222: "}",
}

export const localeMessages = {
  'en-US.json': () => import('../../locales/en-US.json' /* webpackChunkName: "lang-en-US.json" */),
  'nl-NL.json': () => import('../../locales/nl-NL.json' /* webpackChunkName: "lang-nl-NL.json" */),
  'it-IT.json': () => import('../../locales/it-IT.json' /* webpackChunkName: "lang-it-IT.json" */),
  'de-DE.json': () => import('../../locales/de-DE.json' /* webpackChunkName: "lang-de-DE.json" */),
  'fr-FR.json': () => import('../../locales/fr-FR.json' /* webpackChunkName: "lang-fr-FR.json" */),
  'es-ES.json': () => import('../../locales/es-ES.json' /* webpackChunkName: "lang-es-ES.json" */),
}
