
import { settingsStateMapGetter } from '@/services/settingsService'

export default {
  props: {
    classes: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {
      mobileModal: false
    }
  },
  computed: {
    ...settingsStateMapGetter()
  }
}
