
import NavbarAuth from '@/components/navbar/NavbarAuth'
import Footer from '@/components/elements/Footer'

export default {
  components: {
    NavbarAuth,
    Footer
  }
}
