
import { settingsStateMapGetter } from '@/services/settingsService'
import { userAuthStateMapGetter } from '@/services/userService'
import ActiveRacesDropdown from '@/components/elements/ActiveRacesDropdown'
import AddUserteamModal from '@/components/modals/AddUserteamModal'

export default {
  components: {
    ActiveRacesDropdown
  },
  data() {
    return {
      activeRacesDropdownClasses: 'is-small mb-2',
      isDefaultSelected: null
    }
  },
  computed: {
    ...settingsStateMapGetter(),
    ...userAuthStateMapGetter(),
    getUserteam() {
      const userteam = this.loggedInUser.userteams.filter(
        i => i.race_id === this.selectedRace.id
      )

      return userteam[0]
    },
    getPooltype() {
      if (this.settings.pooltype.name_locale_plural) {
        return this.$t(this.settings.pooltype.name_locale_plural)
      }

      return this.$t('menu_pools')
    }
  },
  created() {
    this.selectedRace.rankingtypes.forEach(item => {
      if (item.is_default_selected) {
        this.isDefaultSelected = item.name_locale
      }
    })

    this.$options.menuConfig = [
      {
        name: 'race-raceresults-number',
        label: 'menu_results',
        class: 'mx-1',
        enabled: true,
        tab: this.isDefaultSelected
      },
      {
        name: 'race-stats',
        label: 'menu_stats',
        class: 'mx-1',
        enabled: true,
        tab: 'points'
      },
      {
        name: 'race-stages',
        label: 'menu_stages',
        class: 'mx-1',
        enabled: true,
        tab: null
      },
      {
        name: 'race-teams',
        label: 'menu_teams',
        class: 'mx-1',
        enabled: true,
        tab: null
      },
      {
        name: 'race-prizes',
        label: 'menu_prizes',
        class: 'mx-1',
        enabled: this.settings.enable_prizes,
        tab: null
      },
      {
        name: 'race-rules',
        label: 'menu_rules',
        class: 'ml-1',
        enabled: true,
        tab: null
      }
    ]
  },
  methods: {
    openAddUserteamModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddUserteamModal,
        hasModalCard: true,
        trapFocus: true
      })
    }
  }
}
