import { render, staticRenderFns } from "./admin.vue?vue&type=template&id=51136970&"
import script from "./admin.vue?vue&type=script&lang=js&"
export * from "./admin.vue?vue&type=script&lang=js&"
import style0 from "./admin.vue?vue&type=style&index=0&id=51136970&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarAdmin: require('/app/components/navbar/NavbarAdmin.vue').default})
