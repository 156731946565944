
import { settingsStateMapGetter } from '@/services/settingsService'
import HistoricalRacesDropdown from '@/components/elements/HistoricalRacesDropdown'
import LangSwitcher from '@/components/elements/LangSwitcher'

export default {
  components: {
    HistoricalRacesDropdown,
    LangSwitcher
  },
  computed: {
    ...settingsStateMapGetter()
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank')
    }
  }
}
