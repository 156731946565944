export default $axios => resource => ({
  // Gets
  getProfile() {
    return $axios.$get(`${resource}/profile`)
  },
  // Deletes
  deleteUser(userId) {
    return $axios.$delete(`${resource}/user/${userId}`)
  },
  // Patches
  patchUserprofile(userprofileId, data) {
    return $axios.$patch(`${resource}/userprofile/${userprofileId}`, data)
  }
})
