import moment from 'moment'

import 'moment/locale/nl'
import 'moment/locale/it'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/es'

import 'moment-timezone'

moment.locale('en')

moment.tz.setDefault('Europe/Amsterdam')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
