export const PageTitle = () => import('../../components/PageTitle.vue' /* webpackChunkName: "components/page-title" */).then(c => wrapFunctional(c.default || c))
export const Poolmembers = () => import('../../components/Poolmembers.vue' /* webpackChunkName: "components/poolmembers" */).then(c => wrapFunctional(c.default || c))
export const Pools = () => import('../../components/Pools.vue' /* webpackChunkName: "components/pools" */).then(c => wrapFunctional(c.default || c))
export const Predictions = () => import('../../components/Predictions.vue' /* webpackChunkName: "components/predictions" */).then(c => wrapFunctional(c.default || c))
export const RankingPerWeek = () => import('../../components/RankingPerWeek.vue' /* webpackChunkName: "components/ranking-per-week" */).then(c => wrapFunctional(c.default || c))
export const Rankingpoints = () => import('../../components/Rankingpoints.vue' /* webpackChunkName: "components/rankingpoints" */).then(c => wrapFunctional(c.default || c))
export const RankingpointsOfPool = () => import('../../components/RankingpointsOfPool.vue' /* webpackChunkName: "components/rankingpoints-of-pool" */).then(c => wrapFunctional(c.default || c))
export const Rankingpools = () => import('../../components/Rankingpools.vue' /* webpackChunkName: "components/rankingpools" */).then(c => wrapFunctional(c.default || c))
export const Rankinguserteams = () => import('../../components/Rankinguserteams.vue' /* webpackChunkName: "components/rankinguserteams" */).then(c => wrapFunctional(c.default || c))
export const RankinguserteamsOfPool = () => import('../../components/RankinguserteamsOfPool.vue' /* webpackChunkName: "components/rankinguserteams-of-pool" */).then(c => wrapFunctional(c.default || c))
export const RankinguserteamsOfSeason = () => import('../../components/RankinguserteamsOfSeason.vue' /* webpackChunkName: "components/rankinguserteams-of-season" */).then(c => wrapFunctional(c.default || c))
export const Ranks = () => import('../../components/Ranks.vue' /* webpackChunkName: "components/ranks" */).then(c => wrapFunctional(c.default || c))
export const Userteams = () => import('../../components/Userteams.vue' /* webpackChunkName: "components/userteams" */).then(c => wrapFunctional(c.default || c))
export const AddthisShareButtonPool = () => import('../../components/addthis/ShareButtonPool.vue' /* webpackChunkName: "components/addthis-share-button-pool" */).then(c => wrapFunctional(c.default || c))
export const AdminUsers = () => import('../../components/admin/AdminUsers.vue' /* webpackChunkName: "components/admin-users" */).then(c => wrapFunctional(c.default || c))
export const CardsPrizeCard = () => import('../../components/cards/PrizeCard.vue' /* webpackChunkName: "components/cards-prize-card" */).then(c => wrapFunctional(c.default || c))
export const CardsTeamCard = () => import('../../components/cards/TeamCard.vue' /* webpackChunkName: "components/cards-team-card" */).then(c => wrapFunctional(c.default || c))
export const ElementsActiveRacesDropdown = () => import('../../components/elements/ActiveRacesDropdown.vue' /* webpackChunkName: "components/elements-active-races-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ElementsAfterStageDropdown = () => import('../../components/elements/AfterStageDropdown.vue' /* webpackChunkName: "components/elements-after-stage-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ElementsFooter = () => import('../../components/elements/Footer.vue' /* webpackChunkName: "components/elements-footer" */).then(c => wrapFunctional(c.default || c))
export const ElementsHistoricalRacesDropdown = () => import('../../components/elements/HistoricalRacesDropdown.vue' /* webpackChunkName: "components/elements-historical-races-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ElementsLangSwitcher = () => import('../../components/elements/LangSwitcher.vue' /* webpackChunkName: "components/elements-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const ElementsMenu = () => import('../../components/elements/Menu.vue' /* webpackChunkName: "components/elements-menu" */).then(c => wrapFunctional(c.default || c))
export const ElementsMessage = () => import('../../components/elements/Message.vue' /* webpackChunkName: "components/elements-message" */).then(c => wrapFunctional(c.default || c))
export const ElementsWeekDropdown = () => import('../../components/elements/WeekDropdown.vue' /* webpackChunkName: "components/elements-week-dropdown" */).then(c => wrapFunctional(c.default || c))
export const HomeApiStatusError = () => import('../../components/home/ApiStatusError.vue' /* webpackChunkName: "components/home-api-status-error" */).then(c => wrapFunctional(c.default || c))
export const HomeApiStatusPending = () => import('../../components/home/ApiStatusPending.vue' /* webpackChunkName: "components/home-api-status-pending" */).then(c => wrapFunctional(c.default || c))
export const HomeNews = () => import('../../components/home/News.vue' /* webpackChunkName: "components/home-news" */).then(c => wrapFunctional(c.default || c))
export const HomeNextStage = () => import('../../components/home/NextStage.vue' /* webpackChunkName: "components/home-next-stage" */).then(c => wrapFunctional(c.default || c))
export const HomeResults = () => import('../../components/home/Results.vue' /* webpackChunkName: "components/home-results" */).then(c => wrapFunctional(c.default || c))
export const HomeResultsClimber = () => import('../../components/home/ResultsClimber.vue' /* webpackChunkName: "components/home-results-climber" */).then(c => wrapFunctional(c.default || c))
export const HomeResultsIndividual = () => import('../../components/home/ResultsIndividual.vue' /* webpackChunkName: "components/home-results-individual" */).then(c => wrapFunctional(c.default || c))
export const HomeResultsPoints = () => import('../../components/home/ResultsPoints.vue' /* webpackChunkName: "components/home-results-points" */).then(c => wrapFunctional(c.default || c))
export const HomeResultsStageResults = () => import('../../components/home/ResultsStageResults.vue' /* webpackChunkName: "components/home-results-stage-results" */).then(c => wrapFunctional(c.default || c))
export const HomeResultsYouth = () => import('../../components/home/ResultsYouth.vue' /* webpackChunkName: "components/home-results-youth" */).then(c => wrapFunctional(c.default || c))
export const HomeSocialLinks = () => import('../../components/home/SocialLinks.vue' /* webpackChunkName: "components/home-social-links" */).then(c => wrapFunctional(c.default || c))
export const HomeTopTenPools = () => import('../../components/home/TopTenPools.vue' /* webpackChunkName: "components/home-top-ten-pools" */).then(c => wrapFunctional(c.default || c))
export const HomeTopTenRankingpools = () => import('../../components/home/TopTenRankingpools.vue' /* webpackChunkName: "components/home-top-ten-rankingpools" */).then(c => wrapFunctional(c.default || c))
export const HomeTopTenRankinguserteams = () => import('../../components/home/TopTenRankinguserteams.vue' /* webpackChunkName: "components/home-top-ten-rankinguserteams" */).then(c => wrapFunctional(c.default || c))
export const HomeTopTenUserteams = () => import('../../components/home/TopTenUserteams.vue' /* webpackChunkName: "components/home-top-ten-userteams" */).then(c => wrapFunctional(c.default || c))
export const MessagesMessageWaitingForData = () => import('../../components/messages/MessageWaitingForData.vue' /* webpackChunkName: "components/messages-message-waiting-for-data" */).then(c => wrapFunctional(c.default || c))
export const ModalsAddPoolModal = () => import('../../components/modals/AddPoolModal.vue' /* webpackChunkName: "components/modals-add-pool-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsAddUserteamModal = () => import('../../components/modals/AddUserteamModal.vue' /* webpackChunkName: "components/modals-add-userteam-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsPrivacyModal = () => import('../../components/modals/PrivacyModal.vue' /* webpackChunkName: "components/modals-privacy-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsRenamePoolModal = () => import('../../components/modals/RenamePoolModal.vue' /* webpackChunkName: "components/modals-rename-pool-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsRenameUserteamModal = () => import('../../components/modals/RenameUserteamModal.vue' /* webpackChunkName: "components/modals-rename-userteam-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsTermsModal = () => import('../../components/modals/TermsModal.vue' /* webpackChunkName: "components/modals-terms-modal" */).then(c => wrapFunctional(c.default || c))
export const NavbarAdmin = () => import('../../components/navbar/NavbarAdmin.vue' /* webpackChunkName: "components/navbar-admin" */).then(c => wrapFunctional(c.default || c))
export const NavbarAuth = () => import('../../components/navbar/NavbarAuth.vue' /* webpackChunkName: "components/navbar-auth" */).then(c => wrapFunctional(c.default || c))
export const NavbarClean = () => import('../../components/navbar/NavbarClean.vue' /* webpackChunkName: "components/navbar-clean" */).then(c => wrapFunctional(c.default || c))
export const NavbarFull = () => import('../../components/navbar/NavbarFull.vue' /* webpackChunkName: "components/navbar-full" */).then(c => wrapFunctional(c.default || c))
export const NavbarPromo = () => import('../../components/navbar/NavbarPromo.vue' /* webpackChunkName: "components/navbar-promo" */).then(c => wrapFunctional(c.default || c))
export const ResultsClimber = () => import('../../components/results/Climber.vue' /* webpackChunkName: "components/results-climber" */).then(c => wrapFunctional(c.default || c))
export const ResultsIndividual = () => import('../../components/results/Individual.vue' /* webpackChunkName: "components/results-individual" */).then(c => wrapFunctional(c.default || c))
export const ResultsPoints = () => import('../../components/results/Points.vue' /* webpackChunkName: "components/results-points" */).then(c => wrapFunctional(c.default || c))
export const ResultsStageResults = () => import('../../components/results/StageResults.vue' /* webpackChunkName: "components/results-stage-results" */).then(c => wrapFunctional(c.default || c))
export const ResultsTeam = () => import('../../components/results/Team.vue' /* webpackChunkName: "components/results-team" */).then(c => wrapFunctional(c.default || c))
export const ResultsYouth = () => import('../../components/results/Youth.vue' /* webpackChunkName: "components/results-youth" */).then(c => wrapFunctional(c.default || c))
export const StatsHallOfFame = () => import('../../components/stats/HallOfFame.vue' /* webpackChunkName: "components/stats-hall-of-fame" */).then(c => wrapFunctional(c.default || c))
export const StatsRiderPoints = () => import('../../components/stats/RiderPoints.vue' /* webpackChunkName: "components/stats-rider-points" */).then(c => wrapFunctional(c.default || c))
export const StatsRiderSelected = () => import('../../components/stats/RiderSelected.vue' /* webpackChunkName: "components/stats-rider-selected" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
