import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _be9dc5d0 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _5f2d4610 = () => interopDefault(import('../pages/halloffame/index.vue' /* webpackChunkName: "pages/halloffame/index" */))
const _32a33f4a = () => interopDefault(import('../pages/myaccount/index.vue' /* webpackChunkName: "pages/myaccount/index" */))
const _25ecceab = () => interopDefault(import('../pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _61429a50 = () => interopDefault(import('../pages/promo/index.vue' /* webpackChunkName: "pages/promo/index" */))
const _cc080460 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _2bfb58d8 = () => interopDefault(import('../pages/request/index.vue' /* webpackChunkName: "pages/request/index" */))
const _33ca1164 = () => interopDefault(import('../pages/admin/users.vue' /* webpackChunkName: "pages/admin/users" */))
const _df94b5aa = () => interopDefault(import('../pages/forgotpassword/reset/index.vue' /* webpackChunkName: "pages/forgotpassword/reset/index" */))
const _0aa2a9af = () => interopDefault(import('../pages/verify/_id/_hash/index.vue' /* webpackChunkName: "pages/verify/_id/_hash/index" */))
const _a27a4f8e = () => interopDefault(import('../pages/_race/index.vue' /* webpackChunkName: "pages/_race/index" */))
const _4379b15e = () => interopDefault(import('../pages/_race/stages.vue' /* webpackChunkName: "pages/_race/stages" */))
const _293e89b2 = () => interopDefault(import('../pages/_race/myprediction/index.vue' /* webpackChunkName: "pages/_race/myprediction/index" */))
const _6a344cca = () => interopDefault(import('../pages/_race/prizes.vue' /* webpackChunkName: "pages/_race/prizes" */))
const _209f6935 = () => interopDefault(import('../pages/_race/userteams/index.vue' /* webpackChunkName: "pages/_race/userteams/index" */))
const _633e5dde = () => interopDefault(import('../pages/_race/rules.vue' /* webpackChunkName: "pages/_race/rules" */))
const _8104e0ee = () => interopDefault(import('../pages/_race/stats/index.vue' /* webpackChunkName: "pages/_race/stats/index" */))
const _5512b1c6 = () => interopDefault(import('../pages/_race/teams.vue' /* webpackChunkName: "pages/_race/teams" */))
const _08f9fea8 = () => interopDefault(import('../pages/_race/organizepool.vue' /* webpackChunkName: "pages/_race/organizepool" */))
const _163390c1 = () => interopDefault(import('../pages/_race/pools/index.vue' /* webpackChunkName: "pages/_race/pools/index" */))
const _6fe08df8 = () => interopDefault(import('../pages/_race/rankinguserteams/season.vue' /* webpackChunkName: "pages/_race/rankinguserteams/season" */))
const _7bcdf65b = () => interopDefault(import('../pages/_race/rankinguserteams/weekwinner/_week/index.vue' /* webpackChunkName: "pages/_race/rankinguserteams/weekwinner/_week/index" */))
const _1bd05370 = () => interopDefault(import('../pages/_race/raceresults/_number/index.vue' /* webpackChunkName: "pages/_race/raceresults/_number/index" */))
const _07fa8c5a = () => interopDefault(import('../pages/_race/rankinguserteams/_number/index.vue' /* webpackChunkName: "pages/_race/rankinguserteams/_number/index" */))
const _49dbf50f = () => interopDefault(import('../pages/_race/userteam/_slug/index.vue' /* webpackChunkName: "pages/_race/userteam/_slug/index" */))
const _2caea503 = () => interopDefault(import('../pages/_race/pool/_slug/index.vue' /* webpackChunkName: "pages/_race/pool/_slug/index" */))
const _01f06434 = () => interopDefault(import('../pages/_race/rankingpools/_number/index.vue' /* webpackChunkName: "pages/_race/rankingpools/_number/index" */))
const _737baa64 = () => interopDefault(import('../pages/_race/rankinguserteams/_number/rankingpoints/index.vue' /* webpackChunkName: "pages/_race/rankinguserteams/_number/rankingpoints/index" */))
const _57da7c83 = () => interopDefault(import('../pages/_race/pool/_slug/ranking/_number/index.vue' /* webpackChunkName: "pages/_race/pool/_slug/ranking/_number/index" */))
const _28db9ecd = () => interopDefault(import('../pages/_race/pool/_slug/ranking/_number/rankingpoints/index.vue' /* webpackChunkName: "pages/_race/pool/_slug/ranking/_number/rankingpoints/index" */))
const _86e9434e = () => interopDefault(import('../pages/_race/pool/_slug/ranking/_number/weekwinner/_week/index.vue' /* webpackChunkName: "pages/_race/pool/_slug/ranking/_number/weekwinner/_week/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _2dfb1658,
    name: "index___de"
  }, {
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/es",
    component: _2dfb1658,
    name: "index___es"
  }, {
    path: "/fr",
    component: _2dfb1658,
    name: "index___fr"
  }, {
    path: "/it",
    component: _2dfb1658,
    name: "index___it"
  }, {
    path: "/nl",
    component: _2dfb1658,
    name: "index___nl"
  }, {
    path: "/de/admin",
    component: _be9dc5d0,
    name: "admin___de"
  }, {
    path: "/de/anmeldung",
    component: _cad79e9c,
    name: "login___de"
  }, {
    path: "/de/hall-of-fame",
    component: _5f2d4610,
    name: "halloffame___de"
  }, {
    path: "/de/mein-konto",
    component: _32a33f4a,
    name: "myaccount___de"
  }, {
    path: "/de/passwort-vergessen",
    component: _25ecceab,
    name: "forgotpassword___de"
  }, {
    path: "/de/promo",
    component: _61429a50,
    name: "promo___de"
  }, {
    path: "/de/registrieren",
    component: _cc080460,
    name: "register___de"
  }, {
    path: "/de/request",
    component: _2bfb58d8,
    name: "request___de"
  }, {
    path: "/en/admin",
    component: _be9dc5d0,
    name: "admin___en"
  }, {
    path: "/en/forgot-password",
    component: _25ecceab,
    name: "forgotpassword___en"
  }, {
    path: "/en/hall-of-fame",
    component: _5f2d4610,
    name: "halloffame___en"
  }, {
    path: "/en/login",
    component: _cad79e9c,
    name: "login___en"
  }, {
    path: "/en/my-account",
    component: _32a33f4a,
    name: "myaccount___en"
  }, {
    path: "/en/promo",
    component: _61429a50,
    name: "promo___en"
  }, {
    path: "/en/register",
    component: _cc080460,
    name: "register___en"
  }, {
    path: "/en/request",
    component: _2bfb58d8,
    name: "request___en"
  }, {
    path: "/es/admin",
    component: _be9dc5d0,
    name: "admin___es"
  }, {
    path: "/es/hall-of-fame",
    component: _5f2d4610,
    name: "halloffame___es"
  }, {
    path: "/es/iniciar-sesion",
    component: _cad79e9c,
    name: "login___es"
  }, {
    path: "/es/mi-cuenta",
    component: _32a33f4a,
    name: "myaccount___es"
  }, {
    path: "/es/olvido-su-contrasena",
    component: _25ecceab,
    name: "forgotpassword___es"
  }, {
    path: "/es/promo",
    component: _61429a50,
    name: "promo___es"
  }, {
    path: "/es/registrarse",
    component: _cc080460,
    name: "register___es"
  }, {
    path: "/es/request",
    component: _2bfb58d8,
    name: "request___es"
  }, {
    path: "/fr/admin",
    component: _be9dc5d0,
    name: "admin___fr"
  }, {
    path: "/fr/hall-of-fame",
    component: _5f2d4610,
    name: "halloffame___fr"
  }, {
    path: "/fr/mon-compte",
    component: _32a33f4a,
    name: "myaccount___fr"
  }, {
    path: "/fr/mot-de-passe-oublie",
    component: _25ecceab,
    name: "forgotpassword___fr"
  }, {
    path: "/fr/promo",
    component: _61429a50,
    name: "promo___fr"
  }, {
    path: "/fr/request",
    component: _2bfb58d8,
    name: "request___fr"
  }, {
    path: "/fr/s-identifier",
    component: _cad79e9c,
    name: "login___fr"
  }, {
    path: "/fr/s-inscrire",
    component: _cc080460,
    name: "register___fr"
  }, {
    path: "/it/accedi",
    component: _cad79e9c,
    name: "login___it"
  }, {
    path: "/it/admin",
    component: _be9dc5d0,
    name: "admin___it"
  }, {
    path: "/it/hall-of-fame",
    component: _5f2d4610,
    name: "halloffame___it"
  }, {
    path: "/it/mio-account",
    component: _32a33f4a,
    name: "myaccount___it"
  }, {
    path: "/it/password-dimenticata",
    component: _25ecceab,
    name: "forgotpassword___it"
  }, {
    path: "/it/promo",
    component: _61429a50,
    name: "promo___it"
  }, {
    path: "/it/registrati",
    component: _cc080460,
    name: "register___it"
  }, {
    path: "/it/request",
    component: _2bfb58d8,
    name: "request___it"
  }, {
    path: "/nl/admin",
    component: _be9dc5d0,
    name: "admin___nl"
  }, {
    path: "/nl/hall-of-fame",
    component: _5f2d4610,
    name: "halloffame___nl"
  }, {
    path: "/nl/inloggen",
    component: _cad79e9c,
    name: "login___nl"
  }, {
    path: "/nl/mijn-account",
    component: _32a33f4a,
    name: "myaccount___nl"
  }, {
    path: "/nl/promo",
    component: _61429a50,
    name: "promo___nl"
  }, {
    path: "/nl/registreren",
    component: _cc080460,
    name: "register___nl"
  }, {
    path: "/nl/request",
    component: _2bfb58d8,
    name: "request___nl"
  }, {
    path: "/nl/wachtwoord-vergeten",
    component: _25ecceab,
    name: "forgotpassword___nl"
  }, {
    path: "/de/admin/benutzer",
    component: _33ca1164,
    name: "admin-users___de"
  }, {
    path: "/de/passwort-vergessen/zurucksetzen",
    component: _df94b5aa,
    name: "forgotpassword-reset___de"
  }, {
    path: "/en/admin/users",
    component: _33ca1164,
    name: "admin-users___en"
  }, {
    path: "/en/forgot-password/reset",
    component: _df94b5aa,
    name: "forgotpassword-reset___en"
  }, {
    path: "/es/admin/usuarios",
    component: _33ca1164,
    name: "admin-users___es"
  }, {
    path: "/es/olvido-su-contrasena/restablecer",
    component: _df94b5aa,
    name: "forgotpassword-reset___es"
  }, {
    path: "/fr/admin/utilisateurs",
    component: _33ca1164,
    name: "admin-users___fr"
  }, {
    path: "/fr/mot-de-passe-oublie/reinitialiser",
    component: _df94b5aa,
    name: "forgotpassword-reset___fr"
  }, {
    path: "/it/admin/utenti",
    component: _33ca1164,
    name: "admin-users___it"
  }, {
    path: "/it/password-dimenticata/imposta",
    component: _df94b5aa,
    name: "forgotpassword-reset___it"
  }, {
    path: "/nl/admin/gebruikers",
    component: _33ca1164,
    name: "admin-users___nl"
  }, {
    path: "/nl/wachtwoord-vergeten/instellen",
    component: _df94b5aa,
    name: "forgotpassword-reset___nl"
  }, {
    path: "/de/verifizieren/:id/:hash",
    component: _0aa2a9af,
    name: "verify-id-hash___de"
  }, {
    path: "/en/verify/:id/:hash",
    component: _0aa2a9af,
    name: "verify-id-hash___en"
  }, {
    path: "/es/verificar/:id/:hash",
    component: _0aa2a9af,
    name: "verify-id-hash___es"
  }, {
    path: "/fr/verifier/:id/:hash",
    component: _0aa2a9af,
    name: "verify-id-hash___fr"
  }, {
    path: "/it/verificare/:id/:hash",
    component: _0aa2a9af,
    name: "verify-id-hash___it"
  }, {
    path: "/nl/verifieren/:id/:hash",
    component: _0aa2a9af,
    name: "verify-id-hash___nl"
  }, {
    path: "/de/:race",
    component: _a27a4f8e,
    name: "race___de"
  }, {
    path: "/en/:race",
    component: _a27a4f8e,
    name: "race___en"
  }, {
    path: "/es/:race",
    component: _a27a4f8e,
    name: "race___es"
  }, {
    path: "/fr/:race",
    component: _a27a4f8e,
    name: "race___fr"
  }, {
    path: "/it/:race",
    component: _a27a4f8e,
    name: "race___it"
  }, {
    path: "/nl/:race",
    component: _a27a4f8e,
    name: "race___nl"
  }, {
    path: "/de/:race/etappen",
    component: _4379b15e,
    name: "race-stages___de"
  }, {
    path: "/de/:race/meine-prognose",
    component: _293e89b2,
    name: "race-myprediction___de"
  }, {
    path: "/de/:race/preise",
    component: _6a344cca,
    name: "race-prizes___de"
  }, {
    path: "/de/:race/ranking",
    component: _209f6935,
    name: "race-userteams___de"
  }, {
    path: "/de/:race/regeln",
    component: _633e5dde,
    name: "race-rules___de"
  }, {
    path: "/de/:race/statistik",
    component: _8104e0ee,
    name: "race-stats___de"
  }, {
    path: "/de/:race/teams",
    component: _5512b1c6,
    name: "race-teams___de"
  }, {
    path: "/de/:race/tipprunde-organisieren",
    component: _08f9fea8,
    name: "race-organizepool___de"
  }, {
    path: "/de/:race/tipprunden",
    component: _163390c1,
    name: "race-pools___de"
  }, {
    path: "/en/:race/my-prediction",
    component: _293e89b2,
    name: "race-myprediction___en"
  }, {
    path: "/en/:race/organize-pool",
    component: _08f9fea8,
    name: "race-organizepool___en"
  }, {
    path: "/en/:race/pools",
    component: _163390c1,
    name: "race-pools___en"
  }, {
    path: "/en/:race/prizes",
    component: _6a344cca,
    name: "race-prizes___en"
  }, {
    path: "/en/:race/ranking",
    component: _209f6935,
    name: "race-userteams___en"
  }, {
    path: "/en/:race/rules",
    component: _633e5dde,
    name: "race-rules___en"
  }, {
    path: "/en/:race/stages",
    component: _4379b15e,
    name: "race-stages___en"
  }, {
    path: "/en/:race/statistics",
    component: _8104e0ee,
    name: "race-stats___en"
  }, {
    path: "/en/:race/teams",
    component: _5512b1c6,
    name: "race-teams___en"
  }, {
    path: "/es/:race/clasificacion",
    component: _209f6935,
    name: "race-userteams___es"
  }, {
    path: "/es/:race/equipos",
    component: _5512b1c6,
    name: "race-teams___es"
  }, {
    path: "/es/:race/estadisticas",
    component: _8104e0ee,
    name: "race-stats___es"
  }, {
    path: "/es/:race/etapas",
    component: _4379b15e,
    name: "race-stages___es"
  }, {
    path: "/es/:race/mis-pronosticos",
    component: _293e89b2,
    name: "race-myprediction___es"
  }, {
    path: "/es/:race/organizar-torneo",
    component: _08f9fea8,
    name: "race-organizepool___es"
  }, {
    path: "/es/:race/premios",
    component: _6a344cca,
    name: "race-prizes___es"
  }, {
    path: "/es/:race/reglas",
    component: _633e5dde,
    name: "race-rules___es"
  }, {
    path: "/es/:race/torneos",
    component: _163390c1,
    name: "race-pools___es"
  }, {
    path: "/fr/:race/classement",
    component: _209f6935,
    name: "race-userteams___fr"
  }, {
    path: "/fr/:race/equipes",
    component: _5512b1c6,
    name: "race-teams___fr"
  }, {
    path: "/fr/:race/etapes",
    component: _4379b15e,
    name: "race-stages___fr"
  }, {
    path: "/fr/:race/mes-pronostics",
    component: _293e89b2,
    name: "race-myprediction___fr"
  }, {
    path: "/fr/:race/organiser-un-tournoi",
    component: _08f9fea8,
    name: "race-organizepool___fr"
  }, {
    path: "/fr/:race/prix",
    component: _6a344cca,
    name: "race-prizes___fr"
  }, {
    path: "/fr/:race/regles",
    component: _633e5dde,
    name: "race-rules___fr"
  }, {
    path: "/fr/:race/statistiques",
    component: _8104e0ee,
    name: "race-stats___fr"
  }, {
    path: "/fr/:race/tournois",
    component: _163390c1,
    name: "race-pools___fr"
  }, {
    path: "/it/:race/classifica",
    component: _209f6935,
    name: "race-userteams___it"
  }, {
    path: "/it/:race/gruppi",
    component: _163390c1,
    name: "race-pools___it"
  }, {
    path: "/it/:race/mio-pronostico",
    component: _293e89b2,
    name: "race-myprediction___it"
  }, {
    path: "/it/:race/organizzare-corsa",
    component: _08f9fea8,
    name: "race-organizepool___it"
  }, {
    path: "/it/:race/premi",
    component: _6a344cca,
    name: "race-prizes___it"
  }, {
    path: "/it/:race/regole",
    component: _633e5dde,
    name: "race-rules___it"
  }, {
    path: "/it/:race/squadre",
    component: _5512b1c6,
    name: "race-teams___it"
  }, {
    path: "/it/:race/statistiche",
    component: _8104e0ee,
    name: "race-stats___it"
  }, {
    path: "/it/:race/tappe",
    component: _4379b15e,
    name: "race-stages___it"
  }, {
    path: "/nl/:race/etappes",
    component: _4379b15e,
    name: "race-stages___nl"
  }, {
    path: "/nl/:race/mijn-voorspelling",
    component: _293e89b2,
    name: "race-myprediction___nl"
  }, {
    path: "/nl/:race/ploegen",
    component: _5512b1c6,
    name: "race-teams___nl"
  }, {
    path: "/nl/:race/pool-organiseren",
    component: _08f9fea8,
    name: "race-organizepool___nl"
  }, {
    path: "/nl/:race/pools",
    component: _163390c1,
    name: "race-pools___nl"
  }, {
    path: "/nl/:race/prijzen",
    component: _6a344cca,
    name: "race-prizes___nl"
  }, {
    path: "/nl/:race/spelregels",
    component: _633e5dde,
    name: "race-rules___nl"
  }, {
    path: "/nl/:race/stand",
    component: _209f6935,
    name: "race-userteams___nl"
  }, {
    path: "/nl/:race/statistieken",
    component: _8104e0ee,
    name: "race-stats___nl"
  }, {
    path: "/de/:race/ranking/jahreszeit",
    component: _6fe08df8,
    name: "race-rankinguserteams-season___de"
  }, {
    path: "/en/:race/ranking/season",
    component: _6fe08df8,
    name: "race-rankinguserteams-season___en"
  }, {
    path: "/es/:race/clasificacion/temporada",
    component: _6fe08df8,
    name: "race-rankinguserteams-season___es"
  }, {
    path: "/fr/:race/classement/saison",
    component: _6fe08df8,
    name: "race-rankinguserteams-season___fr"
  }, {
    path: "/it/:race/classifica/la-stagione",
    component: _6fe08df8,
    name: "race-rankinguserteams-season___it"
  }, {
    path: "/nl/:race/stand/seizoen",
    component: _6fe08df8,
    name: "race-rankinguserteams-season___nl"
  }, {
    path: "/de/:race/ranking/wochengewinner/:week",
    component: _7bcdf65b,
    name: "race-rankinguserteams-weekwinner-week___de"
  }, {
    path: "/en/:race/ranking/week-winner/:week",
    component: _7bcdf65b,
    name: "race-rankinguserteams-weekwinner-week___en"
  }, {
    path: "/es/:race/clasificacion/ganador-semanal/:week",
    component: _7bcdf65b,
    name: "race-rankinguserteams-weekwinner-week___es"
  }, {
    path: "/fr/:race/classement/gagnant-hebdomadaire/:week",
    component: _7bcdf65b,
    name: "race-rankinguserteams-weekwinner-week___fr"
  }, {
    path: "/it/:race/classifica/vincitore-settimanale/:week",
    component: _7bcdf65b,
    name: "race-rankinguserteams-weekwinner-week___it"
  }, {
    path: "/nl/:race/stand/weekwinnaar/:week",
    component: _7bcdf65b,
    name: "race-rankinguserteams-weekwinner-week___nl"
  }, {
    path: "/de/:race/ergebnisse/:number",
    component: _1bd05370,
    name: "race-raceresults-number___de"
  }, {
    path: "/de/:race/ranking/:number",
    component: _07fa8c5a,
    name: "race-rankinguserteams-number___de"
  }, {
    path: "/de/:race/team/:slug",
    component: _49dbf50f,
    name: "race-userteam-slug___de"
  }, {
    path: "/de/:race/tipprunde/:slug",
    component: _2caea503,
    name: "race-pool-slug___de"
  }, {
    path: "/de/:race/tipprunden/:number",
    component: _01f06434,
    name: "race-rankingpools-number___de"
  }, {
    path: "/en/:race/pool/:slug",
    component: _2caea503,
    name: "race-pool-slug___en"
  }, {
    path: "/en/:race/pools/:number",
    component: _01f06434,
    name: "race-rankingpools-number___en"
  }, {
    path: "/en/:race/ranking/:number",
    component: _07fa8c5a,
    name: "race-rankinguserteams-number___en"
  }, {
    path: "/en/:race/results/:number",
    component: _1bd05370,
    name: "race-raceresults-number___en"
  }, {
    path: "/en/:race/team/:slug",
    component: _49dbf50f,
    name: "race-userteam-slug___en"
  }, {
    path: "/es/:race/clasificacion/:number",
    component: _07fa8c5a,
    name: "race-rankinguserteams-number___es"
  }, {
    path: "/es/:race/equipo/:slug",
    component: _49dbf50f,
    name: "race-userteam-slug___es"
  }, {
    path: "/es/:race/resultados/:number",
    component: _1bd05370,
    name: "race-raceresults-number___es"
  }, {
    path: "/es/:race/torneo/:slug",
    component: _2caea503,
    name: "race-pool-slug___es"
  }, {
    path: "/es/:race/torneos/:number",
    component: _01f06434,
    name: "race-rankingpools-number___es"
  }, {
    path: "/fr/:race/classement/:number",
    component: _07fa8c5a,
    name: "race-rankinguserteams-number___fr"
  }, {
    path: "/fr/:race/equipe/:slug",
    component: _49dbf50f,
    name: "race-userteam-slug___fr"
  }, {
    path: "/fr/:race/resultats/:number",
    component: _1bd05370,
    name: "race-raceresults-number___fr"
  }, {
    path: "/fr/:race/tournoi/:slug",
    component: _2caea503,
    name: "race-pool-slug___fr"
  }, {
    path: "/fr/:race/tournois/:number",
    component: _01f06434,
    name: "race-rankingpools-number___fr"
  }, {
    path: "/it/:race/classifica/:number",
    component: _07fa8c5a,
    name: "race-rankinguserteams-number___it"
  }, {
    path: "/it/:race/gruppi/:number",
    component: _01f06434,
    name: "race-rankingpools-number___it"
  }, {
    path: "/it/:race/gruppo/:slug",
    component: _2caea503,
    name: "race-pool-slug___it"
  }, {
    path: "/it/:race/risultati/:number",
    component: _1bd05370,
    name: "race-raceresults-number___it"
  }, {
    path: "/it/:race/squadra/:slug",
    component: _49dbf50f,
    name: "race-userteam-slug___it"
  }, {
    path: "/nl/:race/pool/:slug",
    component: _2caea503,
    name: "race-pool-slug___nl"
  }, {
    path: "/nl/:race/pools/:number",
    component: _01f06434,
    name: "race-rankingpools-number___nl"
  }, {
    path: "/nl/:race/stand/:number",
    component: _07fa8c5a,
    name: "race-rankinguserteams-number___nl"
  }, {
    path: "/nl/:race/team/:slug",
    component: _49dbf50f,
    name: "race-userteam-slug___nl"
  }, {
    path: "/nl/:race/uitslagen/:number",
    component: _1bd05370,
    name: "race-raceresults-number___nl"
  }, {
    path: "/de/:race/ranking/:number/tagesgewinner",
    component: _737baa64,
    name: "race-rankinguserteams-number-rankingpoints___de"
  }, {
    path: "/en/:race/ranking/:number/day-winner",
    component: _737baa64,
    name: "race-rankinguserteams-number-rankingpoints___en"
  }, {
    path: "/es/:race/clasificacion/:number/ganador-del-dia",
    component: _737baa64,
    name: "race-rankinguserteams-number-rankingpoints___es"
  }, {
    path: "/fr/:race/classement/:number/gagnant-du-jour",
    component: _737baa64,
    name: "race-rankinguserteams-number-rankingpoints___fr"
  }, {
    path: "/it/:race/classifica/:number/vincitore-giornaliero",
    component: _737baa64,
    name: "race-rankinguserteams-number-rankingpoints___it"
  }, {
    path: "/nl/:race/stand/:number/dagwinnaar",
    component: _737baa64,
    name: "race-rankinguserteams-number-rankingpoints___nl"
  }, {
    path: "/de/:race/tipprunde/:slug/ranking/:number",
    component: _57da7c83,
    name: "race-pool-slug-ranking-number___de"
  }, {
    path: "/en/:race/pool/:slug/ranking/:number",
    component: _57da7c83,
    name: "race-pool-slug-ranking-number___en"
  }, {
    path: "/es/:race/torneo/:slug/clasificacion/:number",
    component: _57da7c83,
    name: "race-pool-slug-ranking-number___es"
  }, {
    path: "/fr/:race/tournoi/:slug/classement/:number",
    component: _57da7c83,
    name: "race-pool-slug-ranking-number___fr"
  }, {
    path: "/it/:race/gruppo/:slug/classifica/:number",
    component: _57da7c83,
    name: "race-pool-slug-ranking-number___it"
  }, {
    path: "/nl/:race/pool/:slug/stand/:number",
    component: _57da7c83,
    name: "race-pool-slug-ranking-number___nl"
  }, {
    path: "/de/:race/tipprunde/:slug/ranking/:number/tagesgewinner",
    component: _28db9ecd,
    name: "race-pool-slug-ranking-number-rankingpoints___de"
  }, {
    path: "/en/:race/pool/:slug/ranking/:number/winner-of-the-day",
    component: _28db9ecd,
    name: "race-pool-slug-ranking-number-rankingpoints___en"
  }, {
    path: "/es/:race/torneo/:slug/clasificacion/:number/ganador-del-dia",
    component: _28db9ecd,
    name: "race-pool-slug-ranking-number-rankingpoints___es"
  }, {
    path: "/fr/:race/tournoi/:slug/classement/:number/gagnant-du-jour",
    component: _28db9ecd,
    name: "race-pool-slug-ranking-number-rankingpoints___fr"
  }, {
    path: "/it/:race/gruppo/:slug/classifica/:number/vincitore-giornaliero",
    component: _28db9ecd,
    name: "race-pool-slug-ranking-number-rankingpoints___it"
  }, {
    path: "/nl/:race/pool/:slug/stand/:number/dagwinnaar",
    component: _28db9ecd,
    name: "race-pool-slug-ranking-number-rankingpoints___nl"
  }, {
    path: "/de/:race/tipprunde/:slug/ranking/:number/wochengewinner/:week",
    component: _86e9434e,
    name: "race-pool-slug-ranking-number-weekwinner-week___de"
  }, {
    path: "/en/:race/pool/:slug/ranking/:number/week-winner/:week",
    component: _86e9434e,
    name: "race-pool-slug-ranking-number-weekwinner-week___en"
  }, {
    path: "/es/:race/torneo/:slug/clasificacion/:number/ganador-semanal/:week",
    component: _86e9434e,
    name: "race-pool-slug-ranking-number-weekwinner-week___es"
  }, {
    path: "/fr/:race/tournoi/:slug/classement/:number/gagnant-hebdomadaire/:week",
    component: _86e9434e,
    name: "race-pool-slug-ranking-number-weekwinner-week___fr"
  }, {
    path: "/it/:race/gruppo/:slug/classifica/:number/vincitore-settimanale/:week",
    component: _86e9434e,
    name: "race-pool-slug-ranking-number-weekwinner-week___it"
  }, {
    path: "/nl/:race/pool/:slug/stand/:number/weekwinnaar/:week",
    component: _86e9434e,
    name: "race-pool-slug-ranking-number-weekwinner-week___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
