
import { settingsStateMapGetter } from '@/services/settingsService'
import NavbarFull from '@/components/navbar/NavbarFull'
import Menu from '@/components/elements/Menu'
import MessageWaitingForData from '@/components/messages/MessageWaitingForData'
import Footer from '@/components/elements/Footer'

export default {
  components: {
    NavbarFull,
    Menu,
    MessageWaitingForData,
    Footer
  },
  computed: {
    ...settingsStateMapGetter()
  }
}
