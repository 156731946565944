
export default {
  computed: {
    getCurrentLocale() {
      const currentLocale = this.$i18n.locales.filter(
        i => i.code === this.$i18n.locale
      )

      return currentLocale[0].name
    },
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    }
  }
}
