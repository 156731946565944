
import { settingsStateMapGetter } from '@/services/settingsService'
import { userAuthStateMapGetter } from '@/services/userService'
import AddUserteamModal from '@/components/modals/AddUserteamModal'

export default {
  computed: {
    ...settingsStateMapGetter(),
    ...userAuthStateMapGetter(),
    getNavbarStyle() {
      return {
        'background-color': this.navbarColor
      }
    },
    getUserteam() {
      const userteam = this.loggedInUser.userteams.filter(
        i => i.race_id === this.selectedRace.id
      )

      return userteam[0]
    }
  },
  methods: {
    openAddUserteamModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddUserteamModal,
        hasModalCard: true,
        trapFocus: true
      })
    },
    async logout() {
      const loadingComponent = this.$buefy.loading.open()
      try {
        await this.$auth.logout()
        loadingComponent.close()
        this.$buefy.toast.open(
          this.$t('page_logout_form_toast_message_success')
        )
        this.$router.push(this.localePath('index'))
      } catch (err) {
        loadingComponent.close()
        this.$buefy.toast.open(
          this.$t('page_logout_form_toast_message_failure')
        )
        this.$sentry.captureException(err)
      }
    }
  }
}
