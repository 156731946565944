
export default {
  layout: 'clean',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  }
}
