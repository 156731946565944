
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { settingsStateMapGetter } from '@/services/settingsService'
import { withCatch } from '@/utils'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      form: {
        name: ''
      }
    }
  },
  computed: {
    ...settingsStateMapGetter()
  },
  methods: {
    async addNewUserteam() {
      const loadingComponent = this.$buefy.loading.open()

      const { error: err } = await withCatch(() =>
        this.$raceApi.postNewUserteam(this.selectedRace.slug, {
          name: this.form.name,
          race_id: this.selectedRace.id
        })
      )

      if (err) {
        loadingComponent.close()
        this.$refs.observer.setErrors(err.response.data.errors)
        this.$buefy.toast.open(
          this.$t('modal_new_userteam_toast_message_failure')
        )
        return
      }

      await this.$auth.fetchUser()
      loadingComponent.close()
      this.$parent.close()
      window.location.reload(true)
      this.$buefy.toast.open(
        this.$t('modal_new_userteam_toast_message_success')
      )
    }
  }
}
