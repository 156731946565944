const middleware = {}

middleware['adminRedirect'] = require('../middleware/adminRedirect.js')
middleware['adminRedirect'] = middleware['adminRedirect'].default || middleware['adminRedirect']

middleware['authRedirect'] = require('../middleware/authRedirect.js')
middleware['authRedirect'] = middleware['authRedirect'].default || middleware['authRedirect']

middleware['authRequired'] = require('../middleware/authRequired.js')
middleware['authRequired'] = middleware['authRequired'].default || middleware['authRequired']

middleware['hasStartedRedirect'] = require('../middleware/hasStartedRedirect.js')
middleware['hasStartedRedirect'] = middleware['hasStartedRedirect'].default || middleware['hasStartedRedirect']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['initApp'] = require('../middleware/initApp.js')
middleware['initApp'] = middleware['initApp'].default || middleware['initApp']

middleware['isPublic'] = require('../middleware/isPublic.js')
middleware['isPublic'] = middleware['isPublic'].default || middleware['isPublic']

export default middleware
