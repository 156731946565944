
import NavbarAdmin from '@/components/navbar/NavbarAdmin'
import Footer from '@/components/elements/Footer'

export default {
  components: {
    NavbarAdmin,
    Footer
  }
}
