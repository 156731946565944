export default function ({app: {router}}, inject) {
  if(false){
    // inject empty gtag function for disabled mode
    inject('gtag', () => {})
    return
  }

  window.dataLayer = window.dataLayer || []
  function gtag () {
    dataLayer.push(arguments)
    if(false){
      console.debug('gtag tracking called with following arguments:', arguments)
    }
  }
  inject('gtag', gtag)
  gtag('js', new Date())
  gtag('config','UA-64772428-1',{
  "anonymize_ip": true,
  "send_page_view": false,
  "linker": {
    "domains": [
      "tourzomer.nl",
      "fantaciclo.it",
      "koersprono.be",
      "grandtourfantasy.com",
      "radsport-tippspiel.de",
      "velofantasy.fr",
      "ciclismofantasy.es"
    ]
  }
})

  if(!false){
    router.afterEach((to) => {
      gtag('config', 'UA-64772428-1', {'page_path': to.fullPath})
    })
  }

  // additional accounts

    gtag('config', 'AW-1026927836',{
  "send_page_view": false
})
}
