
import { settingsStateMapGetter } from '@/services/settingsService'

export default {
  computed: {
    ...settingsStateMapGetter(),
    getNavbarStyle() {
      return {
        'background-color': this.navbarColor
      }
    }
  }
}
